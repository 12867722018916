import React, { ChangeEvent, KeyboardEvent } from 'react'
import BaseInput from 'src/components/BaseInput/BaseInput'
import { getInitials, hideFooterOnFocus, showFooterOnBlur } from 'src/app/helpers'
import CalculatorLink from 'src/components/CalculatorLink/CalculatorLink'
import { getEquivalence, getFinalPrice, selectAmount, selectSelectedProject, selectWhitelabel, setAmount, setStep } from 'src/features/compensation/compensationSlice'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import styles from './AmountInput.module.scss'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
export const AmountInput: React.FC = () => {

  const { query } = useRouter()
  const dispatch = useAppDispatch()
  const { min_unit } = useAppSelector(selectWhitelabel)
  const amount = useAppSelector(selectAmount)
  const { t } = useTranslation()
  const selectedProject = useAppSelector(selectSelectedProject)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.split('.')[1]?.length > 3) { return }
    if (+e.target.value < 0) { e.target.value = '0.001' }
    const tCO2 = e.target.value
    dispatch(setAmount(tCO2))
    if (+tCO2 > 0) {
      const initials = getInitials(query, location.host)
      dispatch(getFinalPrice(initials, +tCO2))
      dispatch(getEquivalence(+tCO2))
    }
  }
  const onEnterNextStep = (e: KeyboardEvent) => {
    if (e.key === 'Enter') { dispatch(setStep(2)) }
  }
  const unit = t('general.Unit.' + (selectedProject?.kind || 'compensation'), { count: +amount })
  return (
    <div className={styles['AmountInput']} >
      <div className="amount-input-container">
        <BaseInput value={amount} onChange={handleChange} type="number" min={min_unit} tag={unit} step={min_unit}
          aria-label="amount input"
          onKeyUp={onEnterNextStep}
          onFocus={hideFooterOnFocus} onBlur={showFooterOnBlur}
        />
        <CalculatorLink />
      </div>
    </div >
  )
}
