import axios from 'axios'
import Cookie from 'js-cookie'
import { TrackEvent, TrackPayload } from './models'

export async function postVisitorAPI(payload: { ct_vid: string }): Promise<any> {
  const { data } = await axios.post('/api/v1/visitors/', payload)
  return data
}

export async function postTrackingEventAPI(event: TrackPayload): Promise<any> {
  const ct_sid_cookie = Cookie.get('ct_sid')
  const ct_vid_cookie = Cookie.get('ct_vid')
  const values: TrackEvent = {
    referral: null,
    integration: null,
    ...event,
    ct_sid: ct_sid_cookie,
    ct_vid: ct_vid_cookie,
  }
  const { data } = await axios.post('/api/v1/visitors/add_track_event/', values)
  return data
}
