import React, { useRef } from 'react'
import { useAppSelector } from 'src/app/hooks'
import { selectWhitelabel } from 'src/features/compensation/compensationSlice'
import Skeleton from 'src/components/Skeleton/Skeleton'
import styles from './BaseInput.module.scss'

interface BaseInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  tag?: string
}

const BaseInput: React.FC<BaseInputProps> = ({ tag, ...props }) => {
  const inputRef = useRef(null)
  const { currency_token } = useAppSelector(selectWhitelabel)
  const handleStep = (fn: string) => () => {
    inputRef.current[fn]()
    inputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }
  return (
    <div className={styles.baseInput}>
      {currency_token ? <div className="input-container" style={{ width: tag?.length ? tag.length * 0.5 + 12 + 'rem' : '13rem' }}>

        <div className="minus-btn" onClick={handleStep('stepDown')}>-</div>
        <div className="input-tag-container  box">
          <input
            ref={inputRef}
            step={1}
            {...props}
          />
          {tag && currency_token && <span className="inside-tag">{tag}</span>}
        </div>
        <div onClick={handleStep('stepUp')} className="plus-btn" >+</div>
      </div> : <Skeleton width="250px" height="30px" />}
    </div>
  )
}
export default BaseInput
