const model = {
  'page_name': ''
}
const changeFooterDisplay = (display) => {
  const footer = document.querySelector('footer')
  if (document.body.clientWidth > 600 || !footer) { return }
  footer.style.display = display
}
export const hideFooterOnFocus = () => changeFooterDisplay('none')

export const showFooterOnBlur = () => changeFooterDisplay('flex')

export const getFormattedPrice = (price: number, locale: string, currency: string) => {

  return new Intl.NumberFormat(locale, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' })
    .format(price || 0)
}
export const getFormattedTonnes = (tonnes: number, locale: string) => {

  return new Intl.NumberFormat(locale,
    { minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(tonnes)
}
export const getFormattedDate = (date: Date, locale: string) => {
  return new Intl.DateTimeFormat(locale, { month: 'short', day: '2-digit', year: 'numeric' }).format(date)
}

export const gtm_view = (data: any) => {
  let final_data = {}
  final_data = { ...model }
  for (const property in data) {
    final_data[property] = data[property]
  }
  (window as any)?.dataLayer?.push(final_data)
}
const redSysStyles = 'display:flex;font-size:20px;color: #777E8B;height: 48px; padding: 8px 16px; border: 1px solid rgba(0, 0, 0, 0.06);border-radius: 8px;'
export const renderRedSysForm =(window:any, ...buttonArgs) => {
  window.getCardInput('card-number',redSysStyles+'width: 250px;')
  window.getExpirationMonthInput('expiration-month', redSysStyles+'width: 75px;')
  window.getExpirationYearInput('expiration-year', redSysStyles+'width: 75px;')
  window.getCVVInput('cvv', redSysStyles+'width: 90px;')
  window.getPayButton(...buttonArgs)

}

export const isNoRedirect = (location:Location,payment_gateway) => {
  const authorized_hosts = ['payments','partners','eventos','events','netzeroaccelerator']
  return authorized_hosts.some(h => location.host.includes(h)) || location.search.includes('noredirect') || payment_gateway ==='NMI'
}
export const getInitials = (query,host:string) => query.initials as string || host.split('.').reverse()[2] || 'localhost'
