import React from 'react'

const Skeleton = ({ width, height = '18px' }) => {
  return <div className="ant-skeleton ant-skeleton-active" style={{ width }}>
    <div className="ant-skeleton-content">
      <ul className="ant-skeleton-paragraph" style={{ margin: '0.2rem 0' }}>
        <li style={{ height }}></li></ul>
    </div>
  </div>
}
export default Skeleton
