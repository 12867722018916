
import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import { selectWhitelabel } from 'src/features/compensation/compensationSlice'
import Skeleton from 'src/components/Skeleton/Skeleton'
import styles from './BaseButton.module.scss'
import tinycolor from 'tinycolor2'
interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  icon?: boolean
}
const BaseButton: React.FC<BaseButtonProps> = ({ label, icon, ...props }) => {

  const { secondary_color, currency_token } = useAppSelector(selectWhitelabel)
  return (
    <div className={styles.baseButton}>
      {currency_token ?
        <button style={{
          backgroundColor: secondary_color,
          borderColor: secondary_color,
          color: tinycolor(secondary_color).getBrightness() > 125 ? 'black' : 'white'
        }}  {...props}     >
          {label}
          {icon && <span className="btn-icon">{icon}</span>}
        </button> : <Skeleton width="352px" height="50px" />}
    </div>
  )
}
export default BaseButton
