import * as api from './api'
import Cookie from 'js-cookie'
import { v4 as UUID } from 'uuid'
import { TrackPayload } from './models'
const getUUId = () => `${UUID()}${UUID()}${UUID()}${UUID()}`

export const checkCookies = () => {
  const ct_sid_cookie = Cookie.get('ct_sid')
  const ct_vid_cookie = Cookie.get('ct_vid')

  Cookie.set('ct_sid', ct_sid_cookie || getUUId(), { expires: new Date(new Date().getTime() + 60 * 60 * 1000), domain: process.env.CT_DOMAIN })
  Cookie.set('ct_vid', ct_vid_cookie || getUUId(), { expires: 720, domain: process.env.CT_DOMAIN })

}

export const trackEvent = (event: TrackPayload) => {
  checkCookies()
  return api.postTrackingEventAPI(event)
}

export const postVisitor = () => {
  checkCookies()
  return api.postVisitorAPI({ ct_vid: Cookie.get('ct_vid') })
}
