import React, { useState } from 'react'
import { Calculator as NewCalculator } from '@climatetradecarbon/climatetrade-calculator'
import { getEquivalence, getFinalPrice, selectWhitelabel, setAmount } from 'src/features/compensation/compensationSlice'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { Trans, useTranslation } from 'next-i18next'
import { trackEvent } from 'src/features/visitors/actions'
import { useRouter } from 'next/router'
import { getInitials, gtm_view } from 'src/app/helpers'
import { TrackPayload } from 'src/features/visitors/models'
import { WHITELABEL_PAGES } from 'src/features/visitors/constants'
import { Box, Button, Column, Counter, Icon, Icons, Modal, Pie, Result, Space, Text } from '@climatetradecarbon/climatetrade-components'
import { CalculatorEquivalence } from 'src/models/calculator-equivalence'
import Link from 'next/link'

/**
 * Caclulator Link
 * @description This component is used to show the calculator link which opens the calculator modal
 * @returns {JSX.Element} CalculatorLink
 */
const sectionToEvent = {
  // Org sections:
  electricity: 'ORG_ELECTRICITY',
  employees: 'ORG_EMPLOYEES_COMMUTE',
  trips: 'ORG_BUSINESS_TRIPS',
  other: 'ORG_OTHERS',

  // Life style sections:
  device: 'LIFE_DEVICES',
  home: 'LIFE_HOME',
  internet: 'LIFE_INTERNET',
  leisure: 'LIFE_LEISURE',
  nutrition: 'LIFE_NUTRITION',
  trip: 'LIFE_TRIP',
  transport: 'LIFE_TRANSPORTATION',

  // Trip sections
  commercial: 'TRIP_FLIGHT',
  car: 'TRIP_CAR',
  hotel: 'TRIP_HOTEL',
}
const CalculatorLink = () => {
  // Get the translation function and the i18n instance to get the current language
  const { t, i18n } = useTranslation()

  const { mandatory_calculator } = useAppSelector(selectWhitelabel)

  const dispatch = useAppDispatch()

  // Call the NextJS router to get the current route
  const router = useRouter()

  const [equivalence, setEquivalence] = useState<CalculatorEquivalence>({})

  const [result, setResult] = useState<number>(0)

  const [visible, setVisible] = useState<boolean>(mandatory_calculator)

  /**
   * Handle changes on calculation results.
   * The result of the calculator (if exists) is set in the store with 3 decimal points format.
   */
  const trackCalculator = (event, eventValue = 'CALCULATOR') => {
    const page_name = WHITELABEL_PAGES[router.pathname] || router.asPath
    const initials = getInitials(router.query, location.host)
    const payload: TrackPayload = {
      url: page_name, event, eventValue, integration: initials
    }
    trackEvent(payload)
    gtm_view({ ...payload, page_name, url: location.href })
  }
  const handleResultChange = (result: number) => {
    if (result) {
      setResult(result)
      const tCO2 = result.toFixed(3)
      dispatch(setAmount(tCO2))
      trackCalculator('STEP_VIEW', 'CALCULATION_FINISHED')
      if (+tCO2 > 0) {
        const initials = getInitials(router.query, location.host)
        dispatch(getFinalPrice(initials, +tCO2))
        dispatch(getEquivalence(+tCO2))
      }
    }
  }
  const onSectionChange = (section: string) => {
    trackCalculator('STEP_VIEW', sectionToEvent[section] || section ||'TRIP_HOTEL')
  }
  // Get the calculator type from the store based on selected whitelabel
  const { calculator_type } = useAppSelector(selectWhitelabel)

  /**
   * This function is used to open the calculator modal.
   * Also sets the page name and the event value to be tracked and send the data to the Tracking API.
   * And finally sends the tracking payload, page name, and page location (url) data to the GTM.
   */
  const openCalculator = () => {
    setVisible(true)
    trackCalculator('ACTION_CLICK', 'CALCULATOR')
  }
  return (
    <div>
      {calculator_type && <>
        <Text>{t('general.KnowYourFootprintClickHere')}</Text>
        <Button size="small" variant="simple" onClick={openCalculator}>
          {t('general.CalculateHere')}
        </Button>
      </>}

      <Modal title={t('calculator.Title')} onClose={() => setVisible(false)} open={visible}>
        <div className="calculator">
          <NewCalculator
            onResultChange={handleResultChange}
            pack={calculator_type}
            language={i18n.language}
            onEquivalenceChange={setEquivalence}
            onSectionChange={onSectionChange}
          />

          <Column>
            <Result
              pie={
                <Pie
                  total={result}
                  description={'tCO2'}
                />
              }
            >
              <Space />

              {!!equivalence.result && equivalence.result > 0 && (
                <Counter
                  size="small"
                  icon="las la-tree"
                  count={equivalence.result || 0}
                  text={equivalence.title}
                />
              )}

              <Box direction='horizontal'>
                <Button
                  variant="primary"
                  onClick={() => window.open('https://climatetrade.com/carbon-emissions-offsetting/', '_blank')}
                >
                  {t('calculator.carbonOffsetting')}
                </Button>

                <Button
                  variant="next"
                  onClick={() => {
                    trackCalculator('ACTION_CLICK', 'OFFSET_MY_FOOTPRINT_BUTTON')
                    setVisible(false)
                  }}
                >
                  {t('calculator.compensateFootprint')}
                  <Icon name={Icons.RIGHT} />
                </Button>
              </Box>
            </Result>

            <div className="terms">
              <Trans
                i18nKey="common:calculator.termsAndConditions"
                components={[
                  <Text key="text" />,
                  <Link
                    key="terms-and-conditions"
                    href={`https://market.climatetrade.com/files/terms_${i18n.language}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ]}
              />
            </div>
          </Column>
        </div>
      </Modal>
    </div>
  )
}

export default CalculatorLink
