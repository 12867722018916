import React from 'react'

import { useTranslation } from 'next-i18next'
import styles from './AmountView.module.scss'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { selectAmount, selectWhitelabel, setStep, } from 'src/features/compensation/compensationSlice'
import BaseButton from 'src/components/BaseButton/BaseButton'
import { AmountInput } from 'src/components/AmountInput/AmountInput'

const AmountView: React.FC<{ showAmountInput: boolean }> = ({ showAmountInput }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const amount = useAppSelector(selectAmount)
  const { welcoming_text } = useAppSelector(selectWhitelabel)
  const handleNextStep = () => dispatch(setStep(2))

  return (
    <div className={styles.AmountView}>
      <div className="amount-layout">
        <div className="offset-title" dangerouslySetInnerHTML={{ __html: welcoming_text || t('general.Title') }} />
        {showAmountInput && <>
          <div className="carbon-tones-container">
            <div className="select-amount-title">{t('general.AmountViewTitle')}</div>
            <AmountInput />
            <BaseButton type="submit" label={t('general.Continue')} disabled={!amount} onClick={handleNextStep} />
          </div>
        </>}
      </div>
    </div>
  )
}
export default AmountView
